import { z } from 'zod'
import { itemListSchema } from 'api/models/commons'

const transformJsonStringToObject = (data: string) => {
  return JSON.parse(data)
}

const stringToBoolean = (val: string) => val === '1'

const notificationSchema = z.object({
  id: z.string().transform(Number),
  label: z.string(),
  notificationStaff: z.string().transform((data) => {
    const parsedData = transformJsonStringToObject(data)
    return {
      id: parsedData.id,
      names: parsedData.names
    }
  }),
  notificationRole: z.string().transform((data) => {
    const parsedData = transformJsonStringToObject(data)
    return {
      id: parsedData.id,
      label: parsedData.label
    }
  }),
  autoRecipient: z.string(),
  additionalRecipients: z.string().transform((data) => {
    const parsedData = transformJsonStringToObject(data)
    return {
      to: parsedData.to
    }
  }),
  isActive: z.string().transform(stringToBoolean),
  isInternal: z.string().transform(stringToBoolean),
  isEditable: z.string().transform(stringToBoolean),
  canNotifyCenter: z.string().transform(stringToBoolean),
  isCenterRecipient: z.string().transform(stringToBoolean),
  description: z.string().nullable()
})
export { NotificationsSchema }
export type Notification = z.infer<typeof notificationSchema>

const notificationListSchema = itemListSchema(notificationSchema)
export type NotificationList = z.infer<typeof notificationListSchema>

const NotificationsSchema = z.array(notificationSchema)
export type Notifications = z.infer<typeof NotificationsSchema>

const notificationUpdateSchema = z.object({
  label: z.string().optional(),
  notificationStaff: z
    .array(
      z.object({
        id: z.number(),
        names: z.string().optional()
      })
    )
    .optional(),
  notificationRole: z
    .array(
      z.object({
        id: z.number(),
        label: z.string().optional()
      })
    )
    .optional(),
  additionalRecipients: z
    .object({
      to: z.array(z.string().email())
    })
    .optional(),
  isActive: z.boolean().optional(),
  isCenterRecipient: z.boolean().optional(),
  description: z.string().nullable().optional()
})

export type NotificationUpdate = z.infer<typeof notificationUpdateSchema>
