import { useTranslation } from 'react-i18next'
import {
  Button,
  Grid,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useFetcher } from 'app/providers/fetcher.provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormCard } from 'app/components/form/form-card.component'
import { EnterpriseInformation, ClientCenter, FormItemValue } from 'api/models'
import {
  ClientCenterForm,
  clientCenterFormSchema,
  ClientCenterMailForm,
  clientCenterMailFormSchema
} from 'api/models/forms/client_center'
import { useApp } from 'app/providers/app.provider'
import { useFeedback } from 'app/providers/feedback.provider'

interface IEnterpriseAccountingInformations {
  enterprise: EnterpriseInformation
}

export function EnterpriseAccountingInformationsComponent({
  enterprise
}: IEnterpriseAccountingInformations) {
  const { t } = useTranslation()
  const { getRight } = useApp()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const [selectedCenter, setSelectedCenter] = useState<FormItemValue | undefined>(undefined)
  const [centers, setCenters] = useState<FormItemValue[]>([])
  const [clientCenter, setClientCenter] = useState<ClientCenter>({} as ClientCenter)
  const { getFilters, getClientCenter, updateClientCenter, updateClientCenterMail } = useFetcher()

  const handleChangeCenter = useCallback(
    (event: SelectChangeEvent) => {
      setSelectedCenter(centers.find((center) => center.id === event.target.value))
    },
    [centers]
  )

  const getCenterFilter = useCallback(async () => {
    try {
      setIsLoading(true)
      const data = await getFilters.mutateAsync({
        variables: ['client_centers'],
        params: {
          client_centers: {
            enterprise: enterprise.id
          }
        }
      })
      if (data && data.client_centers) {
        setSelectedCenter(
          data.client_centers.values.find(
            (center) => center.id === String(enterprise.mainCenter)
          ) ?? data.client_centers.values[0]
        )
        setCenters(data.client_centers.values)
      }
    } finally {
      setIsLoading(false)
    }
  }, [enterprise.id])

  const getAccountingInformations = useCallback(
    async (selectedCenterId: string) => {
      setIsLoading(true)
      try {
        const data = await getClientCenter.mutateAsync({
          id: String(enterprise.id),
          centerId: selectedCenterId
        })
        methods.reset(data)
        methodsMail.reset(data)
        setClientCenter(data)
      } finally {
        setIsLoading(false)
      }
    },
    [getClientCenter, enterprise.id]
  )

  const methods = useForm<ClientCenterForm>({
    mode: 'onChange',
    resolver: zodResolver(clientCenterFormSchema)
  })

  const handleSubmitInformations = async (data: ClientCenterForm) => {
    await handleMutation({
      confirm: {
        title: 'save',
        content: t('confirm_edit_accounting_informations'),
        variant: 'primary'
      },
      onStart: () => setIsLoading(true),
      mutation: updateClientCenter,
      data: {
        data: data,
        id: String(enterprise.id),
        clientCenterId: String(clientCenter.id)
      },
      onSuccess: () => {
        methods.reset(data)
      },
      onError: () => {
        if (selectedCenter !== undefined) {
          getAccountingInformations(selectedCenter.id).then()
        }
      },
      toastSuccess: t('update_accounting_informations_success'),
      toastError: t('error'),
      onEnd: () => {
        setIsLoading(false)
      }
    })
  }

  const methodsMail = useForm<ClientCenterMailForm>({
    mode: 'onChange',
    resolver: zodResolver(clientCenterMailFormSchema)
  })
  const handleSubmitMail = async (data: ClientCenterMailForm) => {
    await handleMutation({
      confirm: {
        title: 'save',
        content: t('confirm_edit_accounting_mail'),
        variant: 'primary'
      },
      onStart: () => setIsLoading(true),
      mutation: updateClientCenterMail,
      data: {
        data: data,
        id: String(enterprise.id),
        clientCenterId: String(clientCenter.id)
      },
      onSuccess: () => {
        methodsMail.reset(data)
      },
      onError: () => {
        if (selectedCenter !== undefined) {
          getAccountingInformations(selectedCenter.id).then()
        }
      },
      toastSuccess: t('update_accounting_mail_success'),
      toastError: t('error'),
      onEnd: () => {
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    getCenterFilter().then()
  }, [])

  useEffect(() => {
    if (selectedCenter !== undefined) {
      getAccountingInformations(selectedCenter.id).then()
    }
  }, [selectedCenter])

  if (centers.length === 0 && !isLoading)
    return (
      <Typography align="center" mt={8}>
        {t('no_invoice_data')}
      </Typography>
    )

  return (
    <>
      <Grid container marginBottom={6}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth size={'small'}>
            <Select value={selectedCenter?.id ?? ''} onChange={handleChangeCenter}>
              {centers.map((center) => {
                return (
                  <MenuItem key={center.id} value={center.id}>
                    {center.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Stack direction="column" spacing={6}>
        {getRight('accounting_client', 'isShow') && (
          <FormProvider {...methods}>
            <FormCard
              isLoading={isLoading}
              control={methods.control}
              items={[
                {
                  type: 'textfield',
                  label: t('accounting_number'),
                  name: 'accountingNumber',
                  required: true
                },
                {
                  type: 'textfield',
                  label: t('accounting_name'),
                  name: 'accountingName',
                  required: true
                },
                {
                  type: 'checkbox',
                  label: t('in_accounting'),
                  name: 'isAccounted',
                  inputProps: { isAccounted: false }
                }
              ]}
            >
              {getRight('accounting_client', 'isEdit') && (
                <Grid item xs={6} textAlign={'center'}>
                  <Button
                    disabled={methods.formState.isSubmitting || !methods.formState.isValid}
                    variant={'contained'}
                    color={'primary'}
                    onClick={methods.control.handleSubmit(handleSubmitInformations)}
                  >
                    {t('save')}
                  </Button>
                </Grid>
              )}
            </FormCard>
          </FormProvider>
        )}
        {getRight('accounting_contact_clientcenter', 'isShow') && (
          <FormProvider {...methodsMail}>
            <FormCard
              isLoading={isLoading}
              control={methodsMail.control}
              items={[
                {
                  type: 'textfield',
                  label: t('accounting_mail'),
                  name: 'accountingMail',
                  required: true
                }
              ]}
            >
              {getRight('accounting_contact_clientcenter', 'isEdit') && (
                <Grid item xs={6} textAlign={'center'}>
                  <Button
                    disabled={methodsMail.formState.isSubmitting || !methodsMail.formState.isValid}
                    variant={'contained'}
                    color={'primary'}
                    onClick={methodsMail.control.handleSubmit(handleSubmitMail)}
                  >
                    {t('save')}
                  </Button>
                </Grid>
              )}
            </FormCard>
          </FormProvider>
        )}
      </Stack>
    </>
  )
}
