import { Typography, Button } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Contract, EndorsementContract } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { AlertBox } from 'app/components/papers/alert-box.component'
import { Dialog, DialogRef } from 'app/components/dialog/dialog.component'
import { ReadonlyEndorsementComponent } from './readonly-endorsement.component'

interface IProps {
  contract: Contract
  isLoading: boolean
  setIsParentLink: (value: boolean) => void
}

export const PopupEndorsementComponent = ({ contract, isLoading }: IProps) => {
  const { t } = useTranslation()
  const { getContractAutoRenewalEndorsements } = useFetcher()
  const [autoRenewalState, setAutoRenewalState] = useState<boolean | null>(null)
  const [autoRenewalData, setAutoRenewalData] = useState<EndorsementContract>()
  const dialogRef = useRef<DialogRef>(null)

  useEffect(() => {
    getContractAutoRenewalEndorsements
      .mutateAsync(contract.id)
      .then((data) => {
        setAutoRenewalState(true)
        setAutoRenewalData(data)
      })
      .catch(() => {
        setAutoRenewalState(false)
      })
  }, [contract])

  const handleOpenModalAutoRenewal = useCallback(() => {
    dialogRef.current?.open()
  }, [])

  if (isLoading) return <CardSkeleton />

  return (
    <>
      <Dialog ref={dialogRef} maxWidth={'md'} actions={<></>} title={''}>
        <ReadonlyEndorsementComponent
          endorsement={contract ?? null}
          endorsementContract={autoRenewalData ?? null}
          isLoading={autoRenewalState === null}
        />
      </Dialog>
      <AlertBox
        isVisible={autoRenewalState}
        isLoading={autoRenewalState === null}
        severity={'info'}
      >
        <Typography>
          {t('endorsement_auto_wording_detail')}
          <Button onClick={handleOpenModalAutoRenewal} variant={'text'}>
            {t('endorsement_auto_wording_detail_button')}
          </Button>
        </Typography>
      </AlertBox>
    </>
  )
}
