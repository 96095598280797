import { Typography, Box, Button } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers-pro'
import dayjs, { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Contract } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate } from 'react-router-dom'
import { useFeedback } from 'app/providers/feedback.provider'

interface IProps {
  contract: Contract
  isLoading: boolean
  setIsParentLink: (value: boolean) => void
}

export const CreateEndorsementComponent = ({ contract, isLoading }: IProps) => {
  const { t } = useTranslation()
  const { createContractEndorsement } = useFetcher()
  const [canCreate, setCanCreate] = useState<boolean>(false)
  const [date, setDate] = useState<Dayjs | null>(dayjs())
  const navigate = useNavigate()
  const { handleMutation } = useFeedback()

  useEffect(() => {
    setCanCreate(
      contract.agreement !== null && (Number(contract.state) === 2 || Number(contract.state) === 3)
    )
    setDate(dayjs(contract.end ?? contract.deadline).add(1, 'day'))
  }, [contract])

  const handleSubmit = useCallback(async () => {
    if (!contract || !date) return
    await handleMutation({
      confirm: {
        content: t('confirm_create_endorsement')
      },
      mutation: createContractEndorsement,
      data: { id: contract.id, date: date.format('YYYY-MM-DD') },
      toastSuccess: t('update_success'),
      toastError: t('error'),
      onSuccess: () => navigate(0)
    })
  }, [contract, date])

  const handleChangeDate = useCallback((date: Dayjs | null) => {
    setDate(date)
  }, [])

  if (isLoading) return <CardSkeleton />
  return canCreate ? (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '10px',
          p: 15
        }}
      >
        <Typography>{t('endorsement_begin')}</Typography>
        <DatePicker
          minDate={dayjs()}
          maxDate={dayjs(contract.end ?? contract.deadline).add(1, 'day')}
          value={date}
          onChange={handleChangeDate}
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
        />
        <Button variant="contained" color="primary" size={'small'} onClick={handleSubmit}>
          {t('endorsement_create')}
        </Button>
      </Box>
    </>
  ) : (
    <Typography sx={{ p: 5 }}>{t('endorsement_unavailable')}</Typography>
  )
}
