import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography, Paper as MuiPaper } from '@mui/material'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useList } from 'app/providers/list.provider'
import dayjs from 'dayjs'
import { CaVsBpChartComponent } from 'modules/stats/components/ca-vs-bp-chart.component'
import { MonthPicker } from 'app/components/filters/month-picker'
import { CaEvolComponent } from 'modules/stats/components/ca-evol.component'
import { useApp } from 'app/providers/app.provider'

export const StatsTurnoverView = () => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { setSearchParams, searchParams } = useFetcher()
  const { filtersList, initFilters } = useList()
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'centers']])
  )

  const paramsFilters = useMemo(() => {
    return {
      centers: {
        default: user?.mainCenter
      }
    }
  }, [user])

  const formatDateBeginEndForMonth = () => {
    const month = searchParams.get('month')
    const dateBegin = dayjs(month).subtract(12, 'months').startOf('month').format('YYYY-MM-DD')
    const dateEnd = dayjs(month).endOf('month').format('YYYY-MM-DD')
    searchParams.set('date_begin', dateBegin)
    searchParams.set('date_end', dateEnd)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if (!user) return

    if (!searchParams.get('month')) {
      const now = dayjs()
      searchParams.set('month', now.subtract(1, 'months').startOf('month').format('YYYY-MM'))
    }
    formatDateBeginEndForMonth()

    initFilters(commonFilters, paramsFilters).then()
  }, [])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('turnover')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => {}}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <MonthPicker
            slug="month"
            label={t('date')}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            views={['month', 'year']}
            disableFuture={true}
            onChange={() => {
              formatDateBeginEndForMonth()
            }}
          />
        </FiltersBox>
      </Grid>
      <Grid container spacing={6}>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">
                  {t('ca')} {t('vs')} {t('BP')}
                </Typography>
              </Box>
              <CaVsBpChartComponent
                height={280}
                params={searchParams}
                commonFilters={commonFilters}
                toolbarShow={false}
              />
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('turnover_evolution')}</Typography>
              </Box>
              <CaEvolComponent height={280} params={searchParams} />
            </MuiPaper>
          </Grid>
        </Grid>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('zoom_types_center')}</Typography>
              </Box>
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('distribution_recurrence_month')}</Typography>
              </Box>
            </MuiPaper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
