import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetcher } from 'app/providers/fetcher.provider'
import { Box, Container, Grid, Paper as MuiPaper, Tooltip, Typography } from '@mui/material'
import { useList } from 'app/providers/list.provider'
import { FiltersBox } from 'app/components/filters/filter-box'
import { useApp } from 'app/providers/app.provider'
import { dates, RangePicker } from 'app/components/filters/range-picker'
import { OpenInFullOutlined, Info } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { OccupancyChart } from 'modules/stats/components/occupancy-chart.component'
import { CaVsBpChartComponent } from 'modules/stats/components/ca-vs-bp-chart.component'
import { SurfaceChart } from 'modules/stats/components/surface-chart.component'
import { PipelineSheetComponent } from 'modules/stats/components/pipeline-sheet.component'
import dayjs from 'dayjs'

export const StatsHealthCenterView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { setSearchParams, searchParams } = useFetcher()
  const { filtersList, handleFilter, initFilters } = useList()

  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([['centers', 'centerId']])
  )

  const paramsFilters = useMemo(() => {
    return {
      centers: {
        multiple: false
      }
    }
  }, [user])

  const makeUrlWithParams = (url: string, isMulti = false) => {
    let urlParams = `${url}?date_begin=${searchParams.get(
      'date_begin'
    )}&date_end=${searchParams.get('date_end')}`
    if (isMulti) {
      urlParams += `&centers=[${searchParams.get('centerId')}]`
      return urlParams
    }
    urlParams += `&centerId=${searchParams.get('centerId')}`
    return urlParams
  }

  useEffect(() => {
    if (!user) return

    if (!searchParams.get('date_begin') || !searchParams.get('date_end')) {
      const now = dayjs()
      searchParams.set(
        'date_begin',
        now.subtract(12, 'months').startOf('month').format('YYYY-MM-DD')
      )
      searchParams.set('date_end', now.endOf('month').format('YYYY-MM-DD'))
    }

    if (!searchParams.get('centerId')) {
      searchParams.set('centerId', String(user.mainCenter))
    }

    initFilters(commonFilters, paramsFilters).then()
  }, [user])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('dashboard_health_center')}
        </Typography>
      </Box>
      <Grid>
        <FiltersBox
          filters={filtersList}
          handleFilters={() => handleFilter(async () => {}, true)}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        >
          <RangePicker
            slug="date"
            label={t('Date')}
            slugs={{ begin: 'date_begin', end: 'date_end' }}
            onChange={() => handleFilter(async () => {}, true)}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            isNoDate={false}
            valueLabel={dates.LAST_12_MONTHS}
          />
        </FiltersBox>
      </Grid>
      <Grid container spacing={6}>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('occupation_rate')}</Typography>
                <Link to={makeUrlWithParams('/statsrateoccupancy')} style={{ zIndex: 10 }}>
                  <OpenInFullOutlined fontSize={'medium'} />
                </Link>
              </Box>
              <OccupancyChart height={280} params={searchParams} />
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">
                  {t('ca')} {t('vs')} {t('BP')}
                </Typography>
                <Link to={makeUrlWithParams('/stats-ca')}>
                  <OpenInFullOutlined fontSize={'medium'} />
                </Link>
              </Box>
              <CaVsBpChartComponent
                height={280}
                params={searchParams}
                commonFilters={commonFilters}
                toolbarShow={true}
              />
            </MuiPaper>
          </Grid>
        </Grid>
        <Grid container item spacing={6} direction="row">
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('price')} / m2</Typography>
                <Link to={makeUrlWithParams('/stats-surface', true)}>
                  <OpenInFullOutlined fontSize={'medium'} />
                </Link>
              </Box>
              <SurfaceChart height={280} params={searchParams} />
            </MuiPaper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPaper>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                gap={4}
                paddingTop={6}
                paddingX={6}
              >
                <Typography variant="h3">{t('actual_pipeline')}</Typography>
                <Tooltip title={t('tooltip_actual_pipeline')} placement="top-start">
                  <Info fontSize={'small'} />
                </Tooltip>
              </Box>
              <PipelineSheetComponent height={290} params={searchParams} />
            </MuiPaper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
