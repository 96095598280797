export const customBlueFlexo = {
  50: '#d6e2e4',
  100: '#c2d4d7',
  200: '#adc5c9',
  300: '#99b7bc',
  400: '#84a9ae',
  500: '#709aa1',
  600: '#5b8c93',
  700: '#477d86',
  800: '#326F78',
  900: '#2d646c'
}

export const customBlueSOCorpo = {
  50: '#e9f5f8',
  100: '#d4eaf2',
  200: '#bfe0ec',
  300: '#aad6e6',
  400: '#95cbe0',
  500: '#80c1da',
  600: '#6bb7d4',
  700: '#56acd0',
  800: '#00ABC3',
  900: '#0099B0'
}

export const customYellowFlexo = {
  50: '#fffaeb',
  100: '#fff4d7',
  200: '#feefc4',
  300: '#feeab0',
  400: '#fee59c',
  500: '#fedf88',
  600: '#feda74',
  700: '#fdd561',
  800: '#fdcf4d',
  900: '#FDCA39'
}
