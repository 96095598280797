import React from 'react'
import { LogoComponent } from 'app/components/logo/logo.component'
import { useTheme as useThemeMui } from '@mui/material/styles'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { Card } from '@mui/material'

const Container = styled('div')(() => ({
  display: 'grid',
  placeItems: 'center',
  height: '100vh'
}))

const Content = styled(Card)(() => ({
  padding: '2rem',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 24,
  '& > div > img': {
    height: 80,
    width: 'unset',
    maxWidth: '100%'
  },
  '& h1, & p': {
    margin: 0
  }
}))

export const MaintenanceView = (): React.JSX.Element => {
  // @ts-ignore
  const { logo } = useThemeMui()
  const { t } = useTranslation()

  return (
    <Container>
      <Content variant="outlined">
        <h1>{t('website_under_maintenance')}</h1>
        <p>{t('sorry_check_back_soon')}</p>
        <LogoComponent src={logo.login} alt={'logo'} />
      </Content>
    </Container>
  )
}
