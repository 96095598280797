import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'

import { List } from 'app/components/lists/list'
import { SearchClientInput } from 'app/components/filters/search-client-input'
import { SearchCenterInput } from 'app/components/filters/search-center-input'
import { ListPagination } from 'app/components/lists/list-pagination'
import { formatCurrency, formatDate, formatInvoiceStatus } from 'app/utils/format'
import type { Invoice, InvoiceSum } from 'api/models'
import { useFetcher } from 'app/providers/fetcher.provider'
import { RangePicker } from 'app/components/filters/range-picker'
import { MonthPicker } from 'app/components/filters/month-picker'
import { useList } from 'app/providers/list.provider'
import { SummaryComponent } from 'app/components/lists/summary.component'
import { MoreTime, DateRange, Soap, PointOfSale, Inventory, TextSnippet } from '@mui/icons-material'
import { useApp } from 'app/providers/app.provider'
import { FiltersContainer } from 'app/components/filters/filters-container'
import { LoadingButton } from '@mui/lab'

export const InvoicesView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user } = useApp()
  const { isLast, total, orderBy, setOffset, setTotal, setIsLast, handleSort, handleFilter } =
    useList()
  const { getInvoices, getInvoiceSum, getInvoicesExport, searchParams, setSearchParams } =
    useFetcher()
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [summary, setSummary] = useState<InvoiceSum>({} as InvoiceSum)
  const [summaryItems, setSummaryItems] = useState<Map<string, string>>(new Map<string, string>())
  const [commonFilters] = useState<Map<string, string>>(
    new Map<string, string>([
      ['invoice_is_mandate', 'is_mandate'],
      ['invoice_type', 'type'],
      ['invoice_status', 'status'],
      ['payment_status', 'payment_states'],
      ['companies', 'company']
    ])
  )
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(true)

  const refreshList = useCallback(async (reset: boolean) => {
    setListIsLoading(true)
    getInvoices
      .mutateAsync()
      .then((data) => {
        setOffset(data.actual)
        setIsLast(data.last)
        setTotal(data.total)
        setInvoices(reset ? data.items : (prev) => [...prev, ...data.items])
      })
      .finally(() => setListIsLoading(false))

    setSummaryIsLoading(true)
    getInvoiceSum
      .mutateAsync()
      .then((sum) => {
        setSummary(sum)
      })
      .finally(() => setSummaryIsLoading(false))
  }, [])

  const handleExport = async () => {
    await getInvoicesExport.mutateAsync()
  }

  useEffect(() => {
    if (summary && Object.keys(summary).length > 0) {
      const newSummaryItems = new Map<string, string>()
      newSummaryItems.set('amount', formatCurrency(summary.amount ?? 0))
      newSummaryItems.set('total_vat', formatCurrency(summary.amountVat ?? 0))
      newSummaryItems.set('amount_ttc', formatCurrency(summary.totalCost ?? 0))
      newSummaryItems.set('due_amount', formatCurrency(summary.dueAmount ?? 0))
      setSummaryItems(newSummaryItems)
    }
  }, [summary])

  return (
    <Container>
      <Box marginBottom="2rem">
        <Typography variant="h2" gutterBottom display="inline">
          {t('invoices_list')}
        </Typography>
      </Box>
      <Grid>
        <FiltersContainer
          commonFilters={commonFilters}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onChange={() => handleFilter(refreshList, true)}
        >
          <SearchCenterInput
            slug="center"
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            byPassAllCenters={true}
            initialValue={user?.mainCenter}
          />
          <SearchClientInput
            slug="client"
            defaultIsIndividual={false}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <RangePicker
            slug="created_at"
            label={'created_at'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <MonthPicker
            slug="due_date"
            label={'due_date'}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <LoadingButton
            size="medium"
            variant="contained"
            startIcon={<TextSnippet />}
            disabled={invoices.length === 0}
            loading={getInvoicesExport.isPending}
            onClick={handleExport}
            data-cy="export-button"
          >
            {t('export')}
          </LoadingButton>
        </FiltersContainer>
      </Grid>
      <Grid>
        <SummaryComponent items={summaryItems} isLoading={summaryIsLoading} />

        <Grid container justifyContent="flex-end" alignItems="center" marginY="8px">
          <Typography variant="body2" gutterBottom>
            {invoices.length} / {total}
          </Typography>
        </Grid>
        <List
          items={invoices}
          columns={[
            {
              label: t('status'),
              slug: 'status',
              text: 'statusLabel',
              valueFormatter: formatInvoiceStatus
            },
            { label: t('reference'), slug: 'reference', link: { base: '/invoices', slug: 'id' } },
            { label: t('type'), slug: 'type' },
            {
              label: t('client'),
              slug: 'client',
              link: { base: '/enterprises', slug: 'clientId' }
            },
            {
              label: t('contract'),
              slug: 'contract',
              link: { base: '/contracts', slug: 'contractId' }
            },
            {
              label: MoreTime,
              tooltip: t('created_at'),
              slug: 'createdAt',
              valueFormatter: formatDate
            },
            {
              label: DateRange,
              tooltip: t('due_date'),
              slug: 'dueDate',
              valueFormatter: formatDate
            },
            { label: Soap, tooltip: t('paid_at'), slug: 'paidAt', valueFormatter: formatDate },
            { label: t('amount'), slug: 'amount', valueFormatter: formatCurrency },
            { label: t('vat'), slug: 'amountVat', valueFormatter: formatCurrency },
            { label: t('total_amount'), slug: 'totalCost', valueFormatter: formatCurrency },
            {
              label: Inventory,
              tooltip: t('due_amount'),
              slug: 'dueAmount',
              valueFormatter: formatCurrency
            },
            { label: PointOfSale, tooltip: t('payment_state'), slug: 'paymentStatusLabel' }
          ]}
          handleReset={() => handleFilter(refreshList, true)}
          sort={orderBy}
          handleSort={(property) => handleSort(() => refreshList(true), property)}
          isLoading={listIsLoading}
        />
        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Typography variant="body2" gutterBottom marginTop={2}>
            {invoices.length} / {total}
          </Typography>
        </Grid>
        {!isLast && !listIsLoading && (
          <ListPagination handleRedirect={() => handleFilter(refreshList, false)} />
        )}
      </Grid>
    </Container>
  )
}
