import React, { useCallback, useEffect, useState } from 'react'
import UnloggedWrapper from 'app/components/UnloggedWrapper'
import { styled } from '@mui/system'
import { CircularProgress, Paper } from '@mui/material'
import { Navigate, useNavigate } from 'react-router-dom'
import { useApp } from 'app/providers/app.provider'
import { Providers } from '@microsoft/mgt-element'
import { appRouter } from 'api'
import { useFeedback } from 'app/providers/feedback.provider'
import { useTranslation } from 'react-i18next'

const Wrapper = styled(Paper)`
  max-width: 520px;
  padding: ${(props) => props.theme.spacing(6)};
  background-color: transparent;
  box-shadow: none;
  text-align: center;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`

export const LoginView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { user, setUser } = useApp()
  const { toast } = useFeedback()
  const navigate = useNavigate()
  const [error, setError] = useState(false)

  const submit = useCallback(async (token: string) => {
    try {
      const user = await appRouter.auth.signInWithAzureSso({ token })

      if (user) {
        setUser(user)

        toast({
          message: "You've successfully signed in!",
          variant: 'success'
        })

        return navigate('/')
      }
    } catch (e) {
      toast({
        message: t('sign_in_error'),
        variant: 'error'
      })
      setError(true)
    }
  }, [])

  useEffect(() => {
    Providers.globalProvider.getAccessToken().then((token: string) => {
      if (token) {
        submit(token).then()
      }
    })
  }, [])

  if (user || error) {
    return <Navigate to="/" />
  }

  return (
    <UnloggedWrapper>
      <Wrapper>
        <CircularProgress />
      </Wrapper>
    </UnloggedWrapper>
  )
}
